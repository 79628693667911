import { useInRouterContext, useLocation, useNavigate } from '@remix-run/react';
// eslint-disable-next-line no-restricted-imports
import { NextRouter, useRouter as useNextRouter } from 'next/router';

import { useFlag } from '@headway/feature-flags/react';
import {
  isCareAppDestination,
  normalizeUrl,
} from '@headway/shared/utils/request';

import { useApplicationName } from '../contexts/ApplicationNameContext';

export type { NextRouter };

/**
 * @deprecated
 * Acts as a facade for the Remix and Next.js routers, aligning their APIs to
 * the Next.js router API.  This allows us to continue to use existing code
 * that relies on the Next.js router API while also supporting the migration to
 * Remix. 

 * Do not use this hook in new Remix code, use the Remix router functions directly.
 */
export function useRouter(): NextRouter {
  /* eslint-disable react-hooks/rules-of-hooks */
  const isRemix = useInRouterContext();
  const remixRoutes = useFlag('agoraRemixRoutes', []);

  const appName = useApplicationName();

  if (isRemix) {
    const location = useLocation();
    const navigate = useNavigate();

    return {
      query: Object.fromEntries(new URLSearchParams(location.search)),
      pathname: location.pathname,
      // @ts-ignore
      replace(...args: Parameters<NextRouter['replace']>) {
        const [url] = args;

        const normalized = normalizeUrl(url);

        if (!remixRoutes.includes(normalized) && !remixRoutes.includes('*')) {
          window.location.href = normalized;
          return Promise.resolve(true);
        }

        navigate(normalized, { replace: true });
      },
      // @ts-ignore
      push(...args: Parameters<NextRouter['push']>) {
        const [url] = args;

        const normalized = normalizeUrl(url);

        if (!remixRoutes.includes(normalized) && !remixRoutes.includes('*')) {
          window.location.href = normalized;
          return Promise.resolve(true);
        }

        navigate(normalized);
      },
      back() {
        navigate(-1);
      },
      asPath: location.pathname + location.search,
      isFallback: false,
      // @ts-ignore
      events: {
        on: () => {
          throw new Error(
            'Router events are not supported in the Remix router facade.'
          );
        },
        off: () => {},
      },
    };
  }
  const nextRouter = useNextRouter();
  return {
    ...nextRouter,
    // mirror the Next.js router API

    replace(...args: Parameters<NextRouter['replace']>) {
      const [url] = args;

      const destination = normalizeUrl(url);

      let shouldUseNextRouter = false;

      if (appName === 'CARE' && isCareAppDestination(destination!)) {
        shouldUseNextRouter = true;
      } else if (
        appName === 'MARKETING' &&
        !isCareAppDestination(destination!)
      ) {
        shouldUseNextRouter = true;
      }

      if (remixRoutes.includes(url) || remixRoutes.includes('*')) {
        window.location.href = destination;
        return Promise.resolve(true);
      } else if (!shouldUseNextRouter) {
        window.location.href = new URL(
          destination,
          isCareAppDestination(url)
            ? process.env.NEXT_PUBLIC_CARE_URL
            : process.env.NEXT_PUBLIC_MARKETING_URL
        ).toString();
        return Promise.resolve(true);
      }

      return nextRouter.replace(...args);
    },

    push(...args: Parameters<NextRouter['push']>) {
      const [url] = args;
      const destination = normalizeUrl(url);

      let shouldUseNextRouter = false;

      if (appName === 'CARE' && isCareAppDestination(destination!)) {
        shouldUseNextRouter = true;
      } else if (
        appName === 'MARKETING' &&
        !isCareAppDestination(destination!)
      ) {
        shouldUseNextRouter = true;
      }

      if (remixRoutes.includes(url) || remixRoutes.includes('*')) {
        window.location.href = destination;
        return Promise.resolve(true);
      } else if (!shouldUseNextRouter) {
        window.location.href = new URL(
          destination,
          isCareAppDestination(url)
            ? process.env.NEXT_PUBLIC_CARE_URL
            : process.env.NEXT_PUBLIC_MARKETING_URL
        ).toString();
        return Promise.resolve(true);
      }

      return nextRouter.push(...args);
    },
  };
  /* eslint-enable react-hooks/rules-of-hooks */
}
